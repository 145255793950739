
.view-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .exerciseDetail-main {
        height: 1%;
        flex: 1;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }

    .top-content {
        margin: 30px;

        ::v-deep .el-breadcrumb__item:last-child {

            .el-breadcrumb__inner {
                color: #13131B;
                font-weight: bold;
            }
        }

        .student-name {
            margin: 20px 0 30px;
            color: #13131B;
        }

        .radio-content {
            display: flex;
            align-items: center;
        }

        ::v-deep .el-checkbox {
            margin-left: 40px;

            .el-checkbox__inner {
                height: 16px;
                width: 16px;

                &:hover {
                    border-color: #1122D8;
                }
            }

            .el-checkbox__label {
                font-size: 16px;
            }

            .el-checkbox__input.is-checked {

                .el-checkbox__inner {
                    background-color: #1122D8;
                    border-color: #1122D8;

                    &::after {
                        height: 8px;
                        left: 5px;
                    }
                }
            }

            .el-checkbox__input.is-checked + .el-checkbox__label {
                color: #1122D8;
                font-size: 16px;
            }
        }

        .radioGroup-purple {
            ::v-deep .el-radio-button {
                margin-left: 16px;

                .el-radio-button__inner {
                    color: #333;
                    border: 1px solid #BFBFBF;
                    border-radius: 4px;
                    padding: 10px 0;
                    width: 64px;
                    font-weight: normal;

                    &:hover {
                        color: #1122D8;
                    }
                }

                &:first-child {
                    margin-left: 0;
                }

                &:first-child, &:last-child {
                    .el-radio-button__inner {
                        border-radius: 0;
                    }
                }

                .el-radio-button__orig-radio:checked + .el-radio-button__inner {
                    color: #fff;
                    background-color: #1122D8;
                    border-color: #1122D8;
                    box-shadow: -1px 0 0 0 #1122D8;
                    border-radius: 4px;
                }
            }
        }
    }

    ::v-deep .el-breadcrumb__item {
        .el-breadcrumb__inner.is-link {
            font-weight: 400;
            color: #606266;
        }
    }
}
